@import "../../assets/styles/utils";

.common-evaluation{
  .reply-form{
    @include res(margin-bottom,.25rem);
    .form-item{
      @include res(margin-bottom, .15rem);
      textarea{
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border-color: #f7f7f7;
        border-radius: .1rem;
        line-height: 1.6;
        transition: all .3s;
        @include res(padding,.3rem);
        &:focus{
          border-color: $color-main;
        }
      }
    }
    .form-btn{
      position: relative;
      display: flex;
      justify-content: flex-end;
      @include res(margin-top, .15rem);
      button{
        width: auto;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: .1rem;
        color: #fff;
        @include res(height,.48rem,(md:.58rem,sm:.78rem,xs:.7rem));
        @include res(padding-left,.5rem,(sm:.7rem));
        @include res(padding-right,.5rem,(sm:.7rem));
      }
      .replyto{
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.28rem));
      }
    }
  }
  .article-reply{
    border-top: solid #f7f7f7;
    color:#666666;
    @include res(border-top-width,.01rem,(md:1px));
    @include res(padding-top,.2rem);
    .reply-title{
      color: #333;
      font-weight: bold;
      @include res(margin-bottom,.3rem);
      @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.38rem));
    }
    .reply-cont{
      .item{
        position: relative;
        &:not(:last-child){
          border-bottom: solid #f7f7f7;
          @include res(border-bottom-width,.01rem,(md:1px));
          @include res(margin-bottom,.25rem);
          @include res(padding-bottom,.25rem);
        }
        .item-reply{
          .title{
            position: relative;
            .avatar{
              border-radius: 50%;
              overflow: hidden;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              @include res(left, -.8rem);
              @include res(width,.6rem);
              @include res(height,.6rem);
              img{
                display: block;
                width: 100%;
                height: 100%;
              }
            }
            .info{
              display: flex;
              .username{
                color: #333;
                @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
              }
              .date{
                color: $color-desc;
                @include res(margin-left,.15rem);
              }
            }
            .reply-btn{
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              color: $color-main;
              transition: color .3s;
              display: flex;
              align-items: center;
              .iconfont{
                display: block;
                @include res(margin-right,.05rem);
                @include res(transform,translateY(10%));
                @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.4rem));
              }
              &:hover{
                color: $color-main-light;
              }
            }
          }
          .text{
            line-height: 1.6;
            @include res(margin-top,.2rem);
          }
        }
        .reply-list{
          background-color: #f8f8f8;
          // border-radius: .12rem;
          @include res(margin-top,.2rem);
          @include res(padding,.1rem .3rem);
          li{
            @include res(padding,.15rem 0);
            &:not(:last-child){
              // border-bottom: solid #e7e7e7;
              // @include res(border-bottom-width,.01rem,(md:1px));
            }
            .title{
              position: relative;
              .info{
                display: flex;
                .username{
                  color: #333;
                  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
                }
                .replyto{
                  @include res(margin-left,.05rem);
                }
                .date{
                  color: $color-desc;
                  @include res(margin-left,.15rem);
                }
              }
              .text{
                line-height: 1.6;
              }
              .reply-btn{
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                color: $color-main;
                transition: color .3s;
                display: flex;
                align-items: center;
                .iconfont{
                  display: block;
                  @include res(margin-right,.05rem);
                  @include res(transform,translateY(10%));
                  @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.4rem));
                }
                &:hover{
                  color: $color-main-light;
                }
              }
            }
            .text{
              line-height: 1.4;
              @include res(margin-top,.1rem);
              a{
                &:hover{
                  color: $color-main;
                }
              }
            }
          }
          .more-btn{
            text-align: center;
            color: $color-main;
            transition: all .3s;
            &:hover{
              color: $color-main-light;
            }
            span{
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}