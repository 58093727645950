@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";
@font-face {
  font-family: 'ali';
  src: url('../fonts/alimamashuheiti.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
a{
  color: inherit;
  &:hover {
    color: inherit;
  }
} 
html{
  font-size: 62.5%;
}
body {
  font-family: "Microsoft YaHei","PingFang SC";
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #f4f7fd;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.3rem));
}


//主体内容部分宽度
.container {
  @include res(padding-left,2.6rem,(md:1rem,xs:.3rem));
  @include res(padding-right,2.6rem,(md:1rem,xs:.3rem));
}

.hide{
  color: transparent;
  opacity: 0;
  visibility: hidden;
  user-select: none;
}
.bold{
  font-weight: bold;
}
.blue{
  color: $color-main;
}
// 更多按钮
.common-more{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: .06rem;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include res(font-size,.14rem,(md:.24rem,sm:.36rem,xs:.28rem));
  @include res(padding,.15rem .3rem,(md:.2rem .3rem,sm:.3rem,xs:.24rem .3rem));
  &::after{
    content: '';
    display: block;
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to right,#4baef3,#147bc1,#4baef3);
    transition: all .3s;
  }
  .iconfont{
    display: inline-flex;
    align-items: center;
    transition: all .3s;
    @include res(margin-left,.05rem);
    @include res(font-size,.14rem,(md:.24rem,sm:.36rem,xs:.28rem));
  }
  &:hover{
    &::after{
      transform: translateX(-50%);
    }
    .iconfont{
      transform: translateX(.05rem);
    }
  }
}

// 渐变背景
.blue-gradient{
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after{
    content: '';
    display: block;
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to right,#4baef3,#147bc1,#4baef3);
    transition: all .3s;
  }
  &:hover{
    &::after{
      transform: translateX(-50%);
    }
  }
}

// 微信二维码弹窗
.wxcode-dialog{
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.2rem;
  box-sizing: border-box;
    padding: 0.5rem 0.3rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: auto;
  .wxcode-title{
    font-weight: bold;
    color: #147bc1;
    @include res(font-size,.24rem,(lg:.30rem,md:.34rem,sm:.40rem,xs:18px));
    text-align: center;
  }
  .wxcode-img {
    position: relative;
    @include res(width,240px,220/240);
    @include res(height,240px,220/240);
    border: 1px solid #e7e7e7;
    border-radius: 0.09rem;
    margin: 0.3rem auto 0.3rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before{
      content: "";
			position: absolute;
			top: 0;
			left: 0%;
			height: 60px;
			width: 100%;
			background: repeating-linear-gradient(rgba(20, 123, 193, 0), rgba(20, 123, 193, 0.2));
			background-attachment: fixed;
			animation: scan 2s linear infinite;
    }
    img{
      @include res(width,230px,210/230);
      @include res(height,230px,210/230);
    }
  }
  .wxcode-p{
    font-size: .14rem;
    color: #333333;
    text-align: center;
  }
}
@keyframes scan {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(240px);
  }
}

// 弹窗
.mask{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
}

.common-dialog{
  display: none;
  background-color: #fff;
  border-radius: .2rem;
  position: fixed;
  z-index: 2001;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  @include res(padding,.4rem);
  @include res(width,42%,(md:50%,sm:60%,xs:94%));
  @include res(left,29%,(md:25%,sm:20%,xs:3%));
  >.dialog-close{
    position: absolute;
    color: #000;
    cursor: pointer;
    transition: all .3s;
    transform-origin: center;
    @include res(top,.2rem);
    @include res(right,.2rem);
    .iconfont{
      @include res(font-size,.3rem,(md:.44rem,sm:.54rem,xs:.4rem));
    }
    &:hover{
      transform: rotate(90deg);
    }
  }
  .login-title{
    color: $color-main;
    text-align: center;
    @include res(margin-bottom,.5rem);
    @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.38rem));
  }
  .edit-title{
    @include res(margin-bottom,.2rem);
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.32rem));
  }
  .login-form{
    max-width: 410px;
    margin: 0 auto;
    @include res(width,60%,(md:73%,sm:85%,xs:100%));
    input[type='checkbox']{
      transform: translateY(1px);
    }
    .form-item{
      position: relative;
      @include res(margin-bottom,.25rem,(md:.3rem));
      input:not([type='checkbox']){
        background-color: #f7f7f7;
        border-radius: .06rem;
        padding: 0 .25rem;
        border-style: solid;
        border-color: #f7f7f7;
        outline: none;
        transition: all .3s;
        @include res(border-width,.01rem,(md:1px));
        @include res(width,100%);
        @include res(height,.6rem,(md:.8rem,sm:1.2rem,xs:1rem));
        &:focus{
          border-color: $color-main;
        }
      }
      .btn{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        padding: 0 .25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $color-main;
        transition: all .3s;
        @include res(height,100%);
        &.send-code{
          &:hover .t{
            @include res(color,$color-main-light);
          }
          .t{
            opacity: 1;
          }
          .num{
            opacity: 0;
          }
        }
        .t{
          opacity: 0;
          transition: color .3s;
        }
        .num{
          opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-red;
        }
      }
      textarea{
        background-color: #f7f7f7;
        border-radius: .06rem;
        padding: .25rem;
        border-style: solid;
        border-color: #f7f7f7;
        outline: none;
        transition: all .3s;
        @include res(border-width,.01rem,(md:1px));
        @include res(width,100%);
        &:focus{
          border-color: $color-main;
        }
      }
    }
    .form-flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #999;
      @include res(margin-top,-.15rem);
      @include res(margin-bottom,.2rem,(md:.25rem));
      .check{
        label{
          cursor: pointer;
          @include res(margin-right,.15rem);
        }
        input{
          margin-left: .08rem;
          margin-right: .05rem;
        }
      }
      span{
        color: $color-main;
        cursor: pointer;
      }
    }
    .form-btn{
      @include res(margin-bottom,.1rem,(md:.2rem));
      button{
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
        color: #fff;
        border-radius: .06rem;
        cursor: pointer;
        transition: all .3s;
        @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.34rem));
        @include res(height,.6rem,(md:.8rem,sm:1.2rem,xs:1rem));
      }
      &.form-btn-flex{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        button{
          width: 46%;
          &:not(.blue-gradient){
            background-color: #333;
            &:hover{
              background-color: #000;
            }
          }
        }
      }
    }
    .form-ys{
      text-align: center;
      color: #999;
      @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.28rem));
      input{
        margin-right: .1rem;
      }
      a{
        color: $color-main;
      }
    }
    .pwd-form{
      display: none;
    }
  }
  .login-other{
    text-align: center;
    color: #999;
    @include res(margin-top,.8rem);
    p{
      @include res(margin-top,.2rem);
    }
    a{
      line-height: 1;
      &:not(:last-child){
        @include res(margin-right,.35rem);
      }
      .iconfont{
        color: $color-main;
        transition: all .3s;
        @include res(font-size,.4rem);
        &:hover{
          color: $color-main-light;
        }
      }
    }
  }
  &.prompt,&.auth{
    @include res(padding,.6rem,(xs:.4rem .2rem));
    .prompt-title{
      text-align: center;
      font-weight: bold;
      color: $color-main;
      @include res(margin-bottom,.25rem);
      @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.36rem));
      img{
        height: auto;
        @include res(width,.74rem,(md:1rem));
        @include res(margin-bottom,.1rem);
      }
    }
    .prompt-desc{
      text-align: center;
      color: $color-desc;
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.32rem));
      a{
        color: $color-main;
        @include res(margin-left,.15rem);
      }
      p{
        @include res(margin-bottom,.2rem);
      }
    }
    .prompt-link{
      text-align: center;
      border-radius: .06rem;
      margin: 0 auto;
      color:#fff;
      cursor: pointer;
      @include res(max-width,2rem,(md:3rem,sm:3.5rem));
      @include res(padding,.1rem 0,(md:.2rem 0));
      @include res(margin-top,.35rem);
      span{
        @include res(margin-left,.15rem);
        @include res(margin-right,.15rem);
      }
    }
  }
  &.note,&.reply{
    .login-form{
      max-width: 800px;
      @include res(width,85%,(md:85%,sm:85%,xs:100%));
      .form-btn{
        max-width: 410px;
        margin: 0 auto;
      }
    }
  }
}


//有二级导航的页面
.nav-container{
  position: relative;
  @include res(padding-left,6.5rem,(md:1rem,xs:.3rem));
  @include res(padding-right,2.6rem,(md:1rem,xs:.3rem));
  .ny-nav{
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: #fff;
    border-radius: .1rem;
    @include res(display,block,(md:flex));
    @include res(margin-bottom,0,(md:.5rem));
    @include res(position,absolute,(md:static));
    @include res(padding,.05rem);
    @include res(width,2.75rem,(md:100%));
    @include res(left,2.6rem,(md:1rem,xs:.3rem));
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.32rem));
    &.fixed{
      position: fixed;
      top: 150px;
    }
    &.abs{
      position: absolute;
      bottom: 0;
      top: auto;
    }
    li{
      border-radius: .08rem;
      position: relative;
      transition: all .3s;
      @include res(width,null,(md:2.7rem,sm:3rem,xs:30%));
      &::after{
        content: '';
        display: block;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 7%;
        transform: translateY(-50%);
        opacity: 0;
        transition: all .3s;
        @include res(width,.04rem,(md:4px));
        @include res(height,.04rem,(md:4px));
      }
      &:not(:last-child){
        @include res(margin-bottom,.05rem,(md:0));
        @include res(margin-right,0,(md:.1rem));
      }
      a{
        display: block;
        
        border-radius: .08rem;
        @include res(padding,.13rem .25rem);
      }
      &:hover{
        color: $color-main;
      }
      &.active{
        background-color: $color-main;
        color: #fff;
        &::after{
          opacity: 1;
        }
      }
    }
  }
}

.ny-title{
  @include res(margin-bottom,.3rem);
  &:not(:first-child){
    @include res(margin-top,.6rem);
  }
  h1{
    font-weight: bold;
    @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.38rem));
  }
}