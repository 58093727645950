.pages, #page ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.3rem;
}

.pages a, .pages li, #page ul a, #page ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f2f2f3;
  transition: 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  transition: all .4s;
  flex-shrink: 0;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  font-size: 0.18rem;
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 0.28rem;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 0.38rem;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 0.3rem;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 0.8rem;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 1rem;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 0.8rem;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 0.8rem;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 1rem;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 0.8rem;
  }
}

.pages a:hover, .pages li:hover, #page ul a:hover, #page ul li:hover {
  background-color: #ebebeb;
  color: #147bc1;
}

.pages a.active, .pages a.xl-active, .pages li.active, .pages li.xl-active, #page ul a.active, #page ul a.xl-active, #page ul li.active, #page ul li.xl-active {
  background-color: #147bc1;
  color: #fff;
}

.pages a.xl-prevPage, .pages a.xl-nextPage, .pages li.xl-prevPage, .pages li.xl-nextPage, #page ul a.xl-prevPage, #page ul a.xl-nextPage, #page ul li.xl-prevPage, #page ul li.xl-nextPage {
  display: none;
}

.pages a.disabled, .pages li.disabled, #page ul a.disabled, #page ul li.disabled {
  opacity: .5;
  pointer-events: none;
}

.pages .page_jump_text, #page ul .page_jump_text {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.pages .page_jump_input, #page ul .page_jump_input {
  width: 0.6rem;
  height: 0.3rem;
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 0.7rem;
  }
}

@media (max-width: 991px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 0.8rem;
  }
}

@media (max-width: 767px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 0.7rem;
  }
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 0.4rem;
  }
}

@media (max-width: 991px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 0.5rem;
  }
}

@media (max-width: 767px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 0.4rem;
  }
}

.pages .page_jump_btn, #page ul .page_jump_btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: all;
}

.pages .page_jump_btn:hover, #page ul .page_jump_btn:hover {
  color: #147bc1;
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}
