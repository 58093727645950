@import "../../assets/styles/utils";

footer{
  background: url(./images/footer_bg.jpg) no-repeat bottom center #f6f6f6;
  background-size: cover;
  color: #999999;
  @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.24rem));
  @include res(margin-top,1rem,(xs:.4rem));
  .footer{
    @include res(padding-top,.7rem);
    .footer-info{
      align-items: flex-start;
      justify-content: space-between;
      color: #333;
      @include res(text-align,left,(xs:center));
      @include res(display,flex,(xs:block));
      .footer-title{
        font-weight: bold;
        color: #999999;
        @include res(margin-bottom,.2rem);
        @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.36rem));
      }
      .foot-contact,.foot-link{
        @include res(margin-bottom,0,(xs:.5rem));
        >div{
          &:not(.footer-title){
            @include res(margin-bottom,.1rem);
          }
        }
      }
      .foot-ewm{
        .footer-title{
          @include res(margin-bottom,.1rem);
        }
        .ewm{
          text-align: center;
          img{
            display: block;
            height: auto;
            margin: 0 auto;
            @include res(width,1rem,(md:1.3rem,sm:1.5rem));
            @include res(margin-bottom,.1rem);
          }
        }
      }
    }
    .copyright{
      text-align: center;
      border-top: solid #dedede;
      @include res(font-size, .14rem,(md:.24rem,sm:.34rem,xs:.22rem));
      @include res(border-top-width,.01rem,(md:1px));
      @include res(padding-top,.1rem,(xs:.2rem));
      @include res(padding-bottom,.1rem,(xs:.2rem));
      @include res(margin-top,.5rem);
      a{
        display: inline-block;
        transition: all .3s;
        &:hover{
          color: #000;
        }
      }
      .t1{
        span{
          margin: 0 .05rem;
        }
        @include res(margin-bottom,.08rem);
      }
      .t2{
        a{
          @include res(margin-right,.3rem);
        }
      }
    }
  }
}