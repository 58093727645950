@import "../../assets/styles/utils";

header{
  &::after{
    content: '';
    display: block;
    width: 100%;
    @include res(height,$header-height-base,$header-height-ratio);
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    background-color: #fff;
    box-shadow: 0 0 .1rem rgba(#000,.1);
    @include res(top,0);
    @include res(height,$header-height-base,$header-height-ratio);
    &.trans{
      transform: translateY(-100%);
    }
    .left{
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      @include res(display,flex);
    }
    .logo{
      @include res(margin-right,.5rem);
      img{
        display: block;
        height: auto;
        @include res(width,1.74rem,(md:2.1rem,sm:2.5rem,xs:2rem));
      }
    }
    .nav{
      height: 100%;
      @include res(display,block,(md:none));
      ul{
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        height: 100%;
        li{
          height: 100%;
          position: relative;
          font-weight: lighter;
          // &:nth-child(2){
          //   .sub-nav{
          //     opacity: 1;
          //     visibility: visible;
          //     transform: translateY(0);
          //   }
          // }
          // 
          // &:hover{
          //   .nav-title{
          //     a,.iconfont{
          //       color: $color-main;
          //     }
          //   }
          //   .sub-nav{
          //     opacity: 1;
          //     visibility: visible;
          //     transform: translateY(0);
          //     // display: flex;
          //   }
          // }
          &.JshoverLi{
            .nav-title{
              a,.iconfont{
                color: $color-main;
              }
            }
            .sub-nav{
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
              // display: flex;
            }
          }
          &.active{
            .nav-title{
              a,.iconfont{
                color: $color-main;
              }
            }
          }
          .nav-title{
            height: 100%;
            position: relative;
            @include res(margin-right,.2rem);
            @include res(margin-left,.1rem);
            a{
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all .3s;
            }
            .iconfont{
              position: absolute;
              left: calc(100% + .05rem);
              top: 50%;
              transform: translateY(-50%);
              transition: all .3s;
              display: none;
              @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.3rem));
            }
          }
          .sub-nav{
            background-color: #f4f7fd;
            @include res(box-shadow,0 0 .1rem rgba(0,0,0,0),(md:0 0 .1rem rgba(0,0,0,.1)));
            @include res(position,fixed,(md:absolute));
            @include res(top,$header-height-base,(md:100%));
            @include res(border-bottom-left-radius,.24rem,(md:0));
            @include res(border-bottom-right-radius,.24rem,(md:0));
            @include res(width,100%,(md:auto));
            border-top: 2px solid #e5e6e8;
            left: 0;
            min-width: 100%;
            opacity: 0;
            visibility: hidden;
            @include res(transform,translateY(0rem),(md:translateY(.05rem)));
            // transition: all .3s;
            @include res(transition,all .0s,(md:all .3s));
            @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.28rem));
            &.flexclass{
              display: flex;
              box-shadow: 0 0.03rem 0.15rem 0 rgba(151,164,178,.2);
              .nav-down-left{
                width: 24%;
                border-right: 2px solid #e5e6e8;
                margin-right: .36rem;
                max-height: calc(100vh - 1.4rem);
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 4px;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                  background: rgba(#BDBDBD,.6);
                }
                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px rgba(0,0,0,0);
                  border-radius: 0;
                  background: rgba(0,0,0,0);
                }
                a{
                  overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                  @include res(padding,.14rem .2rem .14rem 0.05rem);
                  &:hover{
                    background-color: #e0eeff;
                    color: #147bc1;
                  }
                  &.active{
                    background-color: #e0eeff;
                    color: #147bc1;
                  }
                }
              }
              .nav-down-right{
                // padding: .15rem 0;
                width: 75%;
                max-height: calc(100vh - 1.4rem);
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 4px;
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                  background: rgba(#BDBDBD,.6);
                }
                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px rgba(0,0,0,0);
                  border-radius: 0;
                  background: rgba(0,0,0,0);
                }
                .n-d-r-it{
                  display: none;
                  &.show{
                    display: block;
                  }
                  &.hide{
                    display: none;
                  }
                  .rh2{
                    @include res(font-size,.16rem,(md:.2rem));
                    @include res(margin-bottom,.08rem,(md:.2rem));
                    padding-top: .05rem;
                    display: flex;
                    align-items: center;
                    color: #147bc1;
                    h2{
                      font-weight: 800;
                      a{
                        padding-left: 0;
                        padding-right: 0;
                        &:hover{
                          color: #147bc1;
                          background-color: transparent;
                        }
                      }
                    }
                    .iconfont{
                      margin-left: .1rem;
                      transform: rotate(-90deg);
                    }
                  }
                  .r-alist{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    transition: all .3s;
                    .building{
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 100%;
                      flex-direction: column;
                      @include res(margin-top,.3rem,(md:0rem));
                      img{
                        width: .6rem;
                        height: .6rem;
                        margin-bottom: 0.08rem;
                      }
                      .t1{
                        background-image: linear-gradient(to bottom, $blue-gradient);
                        -webkit-background-clip: text;
                        color: transparent;
                        font-family: 'ali';
                        font-weight: bold;
                        @include res(font-size,.20rem,(md:.3rem,sm:.4rem,xs:.32rem));
                      }
                    }
                    a{
                      box-sizing: border-box;
                      transition: all .3s;
                      display: flex;
                      flex-direction: column;
                      @include res(padding-right,.1rem,(md:.2rem));
                      @include res(width,25%,(xs:100%));
                      @include res(margin-top,.01rem,(xs:10px));
                      @include res(margin-bottom,.01rem,(xs:10px));
                      @include res(padding-top,.05rem);
                      @include res(padding-bottom,.15rem);
                      @include res(padding-left,.0rem);
                      // @include res(padding-right,.0rem);
                      &:hover{
                        color: $color-main;
                        background-color: transparent;
                      }
                      .top{
                        color: $color-main;
                      }
                      .bom{
                        @include res(margin-top,.07rem,(xs:10px));
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-right: 0.3rem;
                      }
                    }
                  }
                }
              }
              .close-list{
                cursor: pointer;
                position: absolute;
                right: 15%;
                top: 0.2rem;
                color: #999;
                transition: all .3s;
                .iconfont{
                  @include res(font-size,.22rem,(md:.24rem,sm:.34rem,xs:.28rem));
                }
                &:hover{
                  color: #147bc1;
                }
              }
            }
            a{
              display: block;
              transition: all .3s;
              white-space: nowrap;
              // @include res(width,25%);
              @include res(padding,.1rem .2rem);
              &:hover{
                background-color: $color-main;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex);
      .search-open,.kefu-icon,.notice,.login-btn{
        cursor: pointer;
        color: #666;
        transition: all .3s;
        position: relative;
        height: 100%;
        @include res(margin-right,.2rem);
        .title-icon{
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          .iconfont{
            @include res(font-size,.3rem,(md:.48rem,sm:.58rem,xs:.48rem));
          }
        }
        &:hover{
          .title-icon{color: $color-main;}
        }
      }
      .notice{
        .icon{
          position: relative;
          .dian{
            display: block;
            border-radius: 50%;
            background-color: #df0000;
            position: absolute;
            @include res(width,.05rem,(md:5px));
            @include res(height,.05rem,(md:5px));
            @include res(top,10%);
            @include res(right,30%);
          }
        }
        .notice-list{
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 2;
          background-color: #f4f7fd;
          padding: .1rem;
          padding-right: .05rem;
          border-radius: .1rem;
          box-shadow: 0 0 .15rem rgba(#000,.2);
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          @include res(width,3.1rem,(md:5rem,sm:8rem,xs:6rem));
          @include res(transform,translateX(30%),(xs:translateX(20%)));
          &::after{
            content: '';
            display: block;
            border-style: solid;
            border-color: transparent transparent #f4f7fd transparent;
            position: absolute;
            bottom: 100%;
            z-index: 1;
            @include res(right,30%,(md:31%,xs:22%));
            @include res(border-width,.1rem,(md:10px,sm:8px));
          }
          ul{
            overflow-y: auto;
            padding-right: .05rem;
            @include res(max-height,3.5rem,(md:5rem));
            &::-webkit-scrollbar {
              width: 4px;    
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
              background: rgba(#BDBDBD,.6);
            }
            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px rgba(0,0,0,0);
              border-radius: 0;
              background: rgba(0,0,0,0);
            }
            li{
              border-radius: .1rem;
              background-color: #fff;
              transition: all .3s;
              @include res(padding,.1rem);
              @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.28rem));
              &:not(:last-child){
                margin-bottom: .1rem;
              }
              &:hover{
                .title{
                  color: $color-main;
                }
              }
              .date{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $color-main;
                .icon{
                  @include res(margin-right,.1rem);
                  .iconfont{
                    @include res(font-size,.26rem,(md:.42rem,sm:.5rem,xs:.4rem));
                  }
                }
              }
              .title{
                line-height: 1.4;
                transition: all .3s;
              }
            }
          }
        }
        &:hover{
          .notice-list{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .login-btn{
        margin-right: 0;
        .login-nav{
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 2;
          background-color: #f4f7fd;
          padding: .05rem;
          border-radius: .1rem;
          box-shadow: 0 0 .15rem rgba(#000,.2);
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          white-space: nowrap;
          @include res(transform,translateX(30%),(xs:translateX(20%)));
          &::after{
            content: '';
            display: block;
            border-style: solid;
            border-color: transparent transparent #f4f7fd transparent;
            position: absolute;
            bottom: 100%;
            z-index: 1;
            @include res(right,30%,(md:31%,xs:22%));
            @include res(border-width,.1rem,(md:10px,sm:8px));
          }
          ul{
            li{
              border-radius: .1rem;
              // background-color: #fff;
              transition: all .3s;
              @include res(padding,.05rem);
              @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.28rem));
              &:hover{
                a{
                  color: $color-main;
                }
              }
              a{
                display: block;
                line-height: 1.4;
                transition: all .3s;
              }
            }
          }
        }
        &:hover{
          .login-nav{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .hamburger{
        @include res(margin-left,.2rem);
        @include res(display,none,(md:block));
      }
    }
  }
  .header-search{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: $anime-bezier $anime-duration;
    transform: translateY(-100%);
    @include res(height,$header-height-base,$header-height-ratio);
    &.active{
      transform: translateY(-.01rem);
      box-shadow: 0 0 .1rem rgba(#000,.1);
    }
    &.scroll{
      @include res(height,.9rem,$header-height-ratio);
      .header-search-logo img{
        @include res(width,3rem,(md:4rem,sm:4.5rem,xs:3.2rem));
      }
    }
    .header-search-logo{
      @include res(margin-right,1rem);
      @include res(display,block,(xs:none));
      img{
        display: block;
        height: auto;
        transition: $anime-bezier $anime-duration;
        @include res(width,1.74rem,(md:2.1rem,sm:2.5rem,xs:2rem));
      }
    }
    form{
      flex-grow: 1;
      display: flex;
      align-items: center;
      input{
        flex-grow: 1;
        border: none;
        @include res(height,.5rem);
        @include res(font-size, .18rem,(md:16px));
      }
      button{
        background-color: #e4e4e4;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all .4s;
        @include res(margin-left,.1rem);
        @include res(width,.4rem,(md:40px));
        @include res(height,.4rem,(md:40px));
        .iconfont{
          @include res(font-size,.2rem,(md:20px));
        }
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .header-search-close{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-weight: bold;
      color: #000;
      cursor: pointer;
      margin-left: .1rem;
      @include res(width, .5rem,(md:50px));
      @include res(height,.5rem,(md:50px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        display: inline-block;
        transition: all .4s;
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(#000,.1);
        letter-spacing: .01rem;
        &.active{
          .nav-title .iconfont{
            transform: rotate(90deg);
          }
        }
        .nav-title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.36rem,(sm:.42rem,xs:.32rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.32rem,(sm:.36rem,xs:.26rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .6rem);
          @include res(padding-right, .6rem);
          @include res(padding-bottom,.3rem);
          a{
            display: block;
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}

.aside-zhibo-btn{
  display: none;
  cursor: pointer;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 .1rem rgba(0,0,0,.1);
  transition: all .3s;
  @include res(width,1rem,(md:1.3rem,sm:1.6rem,xs:1.2rem));
  @include res(height,1rem,(md:1.3rem,sm:1.6rem,xs:1.2rem));
  @include res(right,1rem,(md:.5rem,xs:.2rem));
  @include res(bottom,1rem);
  &:hover{
    transform: translateY(-.1rem);
  }
  a{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-main;
    img{
      width: 55%;
      height: auto;
      @include res(margin-bottom,.05rem);
    }
    p{
      @include res(font-size,.16rem,(md:.22rem,sm:.3rem,xs:.24rem));
    }
  }
}